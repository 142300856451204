import React from 'react'

import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import {
  Button,
  Container,
  CenterOnMobile,
  QuizSection,
  QuizContainer,
  QuizTitle,
  QuizText,
  FamilyCouchImage,
} from './styles'
import Analytics from '../../utils/Analytics'

const Quiz = ({ hideImage, calendar, homepage }) => {
  const { familyQuiz, familyQuizMobile } = useStaticQuery(graphql`
    query QuizQuery {
      familyQuiz: file(relativePath: { eq: "mental-load/family-quiz.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      familyQuizMobile: file(relativePath: { eq: "mental-load/family-quiz-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  return (
    <QuizSection id="quiz" fullWidth={hideImage} homepage={homepage}>
      <QuizContainer center={hideImage}>
        <Container fullWidth={hideImage}>
          <QuizTitle fullWidth={hideImage} calendar={calendar}>
            How Much is <span>Your</span> Mental Load Worth?
          </QuizTitle>
          <QuizText fullWidth={hideImage}>
            Take this quiz to find out what you'd make if you were being paid for your mental load
            labor.
          </QuizText>
          <CenterOnMobile>
            <a
              href="https://skylightframe.outgrow.us/skylightframe-3"
              target="_blank"
              title="Calculate your mental load"
              rel="noopener noreferrer"
              onClick={() => Analytics.track('Clicked Calculate Your Mental Load CTA')}
            >
              <Button fullWidth={hideImage} lime>
                Calculate Your Mental Load
              </Button>
            </a>
          </CenterOnMobile>
        </Container>
        <FamilyCouchImage hideImage={hideImage}>
          <GatsbyImage
            image={familyQuiz.childImageSharp.gatsbyImageData}
            alt="Mom, Dad and two kids playing together in the living room"
          />
          <GatsbyImage
            image={familyQuizMobile.childImageSharp.gatsbyImageData}
            alt="Mom, Dad and two kids playing together in the living room"
          />
        </FamilyCouchImage>
      </QuizContainer>
    </QuizSection>
  )
}

Quiz.propTypes = {
  hideImage: PropTypes.bool,
  calendar: PropTypes.bool,
  homepage: PropTypes.bool,
}

export default Quiz
