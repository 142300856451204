import React, { useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { breakpoints } from 'src/utils/styles/global-styles'

import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Helmet } from 'react-helmet'
import SEO from '../components/seo'
import Analytics from '../utils/Analytics'
import Quiz from '../components/Quiz'

import {
  Container,
  CopyChange,
  FamilyImage,
  FamilyScheduleImage,
  FamilyCouchImage,
  SubHeading,
  Button,
  CenterOnMobile,
} from '../components/Quiz/styles'

const MentalLoad = ({
  data: {
    family,
    familyMobile,
    familyCouch,
    familyCouchMobile,
    familySchedule,
    familyScheduleMobile,
    chat,
    pie,
    couples,
    couple,
    diaper,
    events,
  },
}) => {
  useEffect(() => {
    Analytics.track('Viewed Mental Load Report Landing Page')
  }, [])

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'DefinedTerm',
            name: 'Mental Load',
            description:
              "Mental load refers to the cognitive effort required to manage various tasks, responsibilities, and concerns in daily life. It encompasses the invisible labor involved in organizing, planning, and remembering, which often goes unrecognized but significantly impacts one's mental well-being and productivity.",
            inDefinedTermSet: {
              '@type': 'DefinedTermSet',
              name: 'Psychology Terms',
            },
          })}
        </script>
      </Helmet>
      <SEO
        title="Mental Load: A Report on Invisible Labor and Its Effects | Skylight"
        description="Family management doesn't just feel like a full-time job—our report shows it truly is. Discover the hidden impact of mental load and how it affects daily life, work, and emotional well-being."
        imagePath={family.childImageSharp.gatsbyImageData.images.fallback.src}
        path="/mental-load-report/"
      />
      <MentalLoadPage>
        <Hero>
          <Container>
            <Heading>
              <span className="nowrap">
                The <span className="highlight">Skylight </span>
              </span>
              <span className="break">Mental Load Report</span>
            </Heading>
            <FamilyImage>
              <GatsbyImage
                image={family.childImageSharp.gatsbyImageData}
                alt="Mom, Dad and two kids playing together in the living room"
              />
              <GatsbyImage
                image={familyMobile.childImageSharp.gatsbyImageData}
                alt="Mom, Dad and two kids playing together in the living room"
              />
            </FamilyImage>
            <Subheading>
              Numbers Show That Family Management Is{' '}
              <MediumUnderline glow>Nearly a Full-Time Job</MediumUnderline>
            </Subheading>
            <CtaContainer>
              <a
                href="https://skylight-marketing-assets.s3.amazonaws.com/Skylight_Mental_Load_Report.pdf"
                target="_blank"
                title="Read mental load report"
                rel="noopener noreferrer"
                onClick={() => Analytics.track('Clicked Read Report CTA')}
              >
                <Button>READ REPORT</Button>
              </a>
              <a
                href="https://skylightframe.outgrow.us/skylightframe-3"
                target="_blank"
                title="Calculate your mental load"
                rel="noopener noreferrer"
                onClick={() => Analytics.track('Clicked Calculate Your Mental Load CTA')}
              >
                <Button>CALCULATE YOUR MENTAL LOAD</Button>
              </a>
            </CtaContainer>
            <CopyChange>
              <Text white>
                Together with The Harris Poll, we surveyed over 2,000 parents to lay bare the
                figures behind the parental mental load. If you're a parent, you don't need a report
                to tell you this. But it helps to know you're not alone and that there are tools to
                help manage it all.
              </Text>
              <Text white>
                Together with The Harris Poll, we surveyed over 2,000 parents to lay bare the
                figures behind the parental mental load.
              </Text>
            </CopyChange>
          </Container>
        </Hero>
        <ParentalSection>
          <Container>
            <ParentalTitle>
              <SmallUnderline glow>Parental Mental Load (noun):</SmallUnderline>
            </ParentalTitle>
            <Phonetic>/ˈpɛərəntl ˈmɛntl loʊd/</Phonetic>
            <CopyChange>
              <Definition>
                You know, the 402-ish tasks whizzing around your brain — remember soccer cleats and
                saxophone reeds and gluten-free, nut-free birthday cupcakes that have to be
                delivered at exactly 2:15 on Tuesday — yeah, <span>that</span> never-ending list.
              </Definition>
              <Definition>
                The 402-ish tasks whizzing around your brain: remember soccer cleats and saxophone
                reeds and th — yeah, <span>that</span> never-ending list.
              </Definition>
            </CopyChange>
          </Container>
        </ParentalSection>
        <ParentsSection>
          <Container>
            <ParentsSpendContainer>
              <Details>Parents spend</Details>
              <Wrapper>
                <HighlightedText medium>30.4 hours per week</HighlightedText>
              </Wrapper>
              <CopyChange>
                <Details>
                  planning and coordinating family schedules and household tasks. Almost a full-time
                  job.
                </Details>
                <Details>planning and coordinating family schedules and household tasks.</Details>
              </CopyChange>
            </ParentsSpendContainer>
            <TwoColumnContainer column>
              <FamilyCouchImage>
                <GatsbyImage
                  image={familyCouch.childImageSharp.gatsbyImageData}
                  alt="Mom, Dad and two kids playing together in the living room"
                />
                <GatsbyImage
                  image={familyCouchMobile.childImageSharp.gatsbyImageData}
                  alt="Mom, Dad and two kids playing together in the living room"
                />
              </FamilyCouchImage>
              <SpecialStatsContainer left>
                <Stats>The mental load occupies </Stats>
                <Wrapper>
                  <HighlightedText xl>63%</HighlightedText>
                </Wrapper>
                <Stats>of parents' brain space on an average day.</Stats>
              </SpecialStatsContainer>
            </TwoColumnContainer>
            <StatsTextContainer>
              <Wrapper>
                <HighlightedText large>$60,000*</HighlightedText>
              </Wrapper>
              <Details>
                That's about how much the average parent would earn if all their mental load work
                turned into a paid job.
              </Details>
              <Disclaimer>
                *How do we figure? Our study calculated the total time occupied by mental load
                tasks, then multiplied it by a median hourly household income of $35.86 per hour.
              </Disclaimer>
            </StatsTextContainer>
            <div>
              <a
                href="https://skylight-marketing-assets.s3.amazonaws.com/Skylight_Mental_Load_Report.pdf"
                target="_blank"
                title="Read mental load report"
                rel="noopener noreferrer"
                onClick={() => Analytics.track('Clicked Read Report CTA')}
              >
                <Button>READ REPORT</Button>
              </a>
            </div>
          </Container>
        </ParentsSection>
        <a
          href="https://skylightframe.outgrow.us/skylightframe-3"
          target="_blank"
          title="Calculate your mental load"
          rel="noopener noreferrer"
          onClick={() => Analytics.track('Clicked Take The Quiz CTA')}
        >
          <QuizBanner>
            <p>Take the Quiz</p>
          </QuizBanner>
        </a>
        <Quiz />
        <OverScheduledSection>
          <StandardContainer>
            <StatsHeading>Overscheduled</StatsHeading>
            <StatsSubheading>
              The average family is over-scheduled and over-emailed (and texted, and called).
            </StatsSubheading>
            <ChatContainer>
              <FirstDiv>
                <EventImage>
                  <GatsbyImage
                    image={events.childImageSharp.gatsbyImageData}
                    alt="Mom, Dad and two kids playing together in the living room"
                  />
                </EventImage>
              </FirstDiv>
              <SecondDiv>
                <StatContainer left>
                  <ChatStats>Children participate in an average of</ChatStats>
                  <Wrapper>
                    <HighlightedText large>11.5 activities </HighlightedText>&nbsp;
                    <HighlightedText large> per week.</HighlightedText>
                  </Wrapper>
                  <ChatStats>That's 598 per year (yes, really).</ChatStats>
                </StatContainer>
              </SecondDiv>
            </ChatContainer>
            <FlexBox reverse>
              <StatsSubheading>
                Families receive an average of{' '}
                <b>17.5 communications about their kids' activities per week</b> (that's about 2 per
                day or 912 per year).
              </StatsSubheading>
              <ChatImage>
                <GatsbyImage
                  image={chat.childImageSharp.gatsbyImageData}
                  alt="Mom, Dad and two kids playing together in the living room"
                />
              </ChatImage>
            </FlexBox>
            <Divider />
            <StatsHeading>The Toll It Takes</StatsHeading>
            <StatsTextContainer>
              <Stats>
                Turns out, there are tradeoffs for spending so much time and energy running a
                household.
              </Stats>
            </StatsTextContainer>
            <FlexBox reverse>
              <StatContainer>
                <Wrapper>
                  <HighlightedText xl>61%</HighlightedText>
                </Wrapper>
                <Stats>
                  of parents say that the scheduling load has{' '}
                  <b>decreased their time with their partner.</b>
                </Stats>
              </StatContainer>
              <PieImage>
                <GatsbyImage
                  image={pie.childImageSharp.gatsbyImageData}
                  alt="Mom, Dad and two kids playing together in the living room"
                />
              </PieImage>
            </FlexBox>
            <FlexBox>
              <CouplesImage>
                <GatsbyImage
                  image={couples.childImageSharp.gatsbyImageData}
                  alt="Mom, Dad and two kids playing together in the living room"
                />
              </CouplesImage>
              <StatContainer left>
                <Stats>Almost</Stats>
                <Wrapper>
                  <HighlightedText xl>1 in 4</HighlightedText>
                </Wrapper>
                <Stats>
                  couples have <b>sought out couples therapy</b> due to the burden of family
                  scheduling.
                </Stats>
              </StatContainer>
            </FlexBox>
            <StatsTextContainer>
              <StatsSubheading>
                So it's no wonder that almost 1 in 3 parents would rather{' '}
                <b>give up sex for a year than give up access to their calendar.</b>
              </StatsSubheading>
            </StatsTextContainer>
            <Divider />
            <StatsHeading>Unequal Task Distribution</StatsHeading>
            <StatsSubheading>
              For partnered parents, “who does more?” is a hot topic.
            </StatsSubheading>
            <TaskStatsTextContainer>
              <Wrapper>
                <HighlightedText>On average, all partnered parents </HighlightedText>
                <HighlightedText>feel they carry the majority of the</HighlightedText>
                <HighlightedText> mental load.</HighlightedText>
              </Wrapper>{' '}
            </TaskStatsTextContainer>
            <CoupleImage>
              <GatsbyImage
                image={couple.childImageSharp.gatsbyImageData}
                alt="Mom, Dad and two kids playing together in the living room"
              />
            </CoupleImage>
            <StatsTextContainer>
              <StatsSubheading>
                <b>Primary caregivers</b> say they
                <b> carry 75% of the mental load. Non-primary caregivers</b> say they
                <b> take on 56%</b> of it.
              </StatsSubheading>
            </StatsTextContainer>
            <ChatStats>Add it together, and at</ChatStats>
            <Wrapper>
              <HighlightedText xl>131%</HighlightedText>
            </Wrapper>
            <ChatStats>the (mental load) limit does not exist.</ChatStats>
            <Divider />
            <StatsHeading>It's a Dirty Job</StatsHeading>
            <StatsSubheading>
              Here are the tradeoffs parents would make for a minute of peace and quiet.
            </StatsSubheading>
            <Wrapper>
              <HighlightedText xl>46%</HighlightedText>
            </Wrapper>
            <StatsTextContainer>
              <StatsSubheading>
                of parents would prefer a year without bathroom interruptions over a year without
                cleaning bathrooms.
              </StatsSubheading>
            </StatsTextContainer>
            <FlexBox>
              <DiaperImage>
                <GatsbyImage image={diaper.childImageSharp.gatsbyImageData} alt="Baby diaper" />
              </DiaperImage>
              <StatsSubheading>
                42% of parents would prefer a year without attending other children's birthday
                parties over a year without diaper changes.
              </StatsSubheading>
            </FlexBox>
          </StandardContainer>
        </OverScheduledSection>
        <ParentalSection>
          <StandardContainer>
            <SchedulingTwoColumnContainer>
              <SchedulingReliefTextContainer>
                <SchedulingTitle>Scheduling Relief</SchedulingTitle>
                <CopyChange>
                  <div>
                    <SchedulingText>
                      The data is clear: parents today could use a chief of staff. But the good
                      news? Even without hiring a team of assistants, there are tools that can help.
                      And when they work well, they <b>really do lighten the mental load. </b>
                    </SchedulingText>
                    <SchedulingText>
                      Our report tells the full story, which includes plenty of hope for getting
                      back to the joyful parts of parenting. <b>You don't want to miss it.</b>
                    </SchedulingText>
                  </div>
                  <div>
                    <SchedulingText>
                      Parents today could use a chief of staff. But there are tools that can help{' '}
                      <b>lighten the mental load.</b> Our report tells the full story, which
                      includes hope for getting back to the joyful parts of parenting.{' '}
                      <b>You don't want to miss it.</b>
                    </SchedulingText>
                  </div>
                </CopyChange>
                <CenterOnMobile>
                  <a
                    href="https://skylight-marketing-assets.s3.amazonaws.com/Skylight_Mental_Load_Report.pdf"
                    target="_blank"
                    title="Read mental load report"
                    rel="noopener noreferrer"
                    onClick={() => Analytics.track('Clicked Read Report CTA')}
                  >
                    <Button lime>READ REPORT</Button>
                  </a>
                </CenterOnMobile>
              </SchedulingReliefTextContainer>
              <FamilyScheduleImage>
                <GatsbyImage
                  image={familySchedule.childImageSharp.gatsbyImageData}
                  alt="Mom and daughter in the kitchen making a meal following Skylight Calendar's meal planning feature"
                />
                <GatsbyImage
                  image={familyScheduleMobile.childImageSharp.gatsbyImageData}
                  alt="Mom and daughter in the kitchen making a meal following Skylight Calendar's meal planning feature"
                />
              </FamilyScheduleImage>
            </SchedulingTwoColumnContainer>
          </StandardContainer>
        </ParentalSection>
        <SignUpSection>
          <LargeContainer>
            <SignUpSubheading>
              <MediumUnderline glow>Help is On the Way</MediumUnderline>
            </SignUpSubheading>
            <SignUpText white>
              Sign up for our Mental Load Resource Kit, created by parents, for parents. Get tips,
              tricks, and hacks to help manage the mental load.
            </SignUpText>
            <SignUpFormContainer>
              <div className="klaviyo-form-VNd796" />
            </SignUpFormContainer>
            <Disclaimer s>
              *By submitting your email, you agree that you are providing your information to
              Skylight for use in accordance with its privacy policy, which you can find
              <Link to="/privacy-policy">
                {' '}
                <span>here</span>
              </Link>
              . No spam, we promise.
            </Disclaimer>
          </LargeContainer>
        </SignUpSection>
        <MethodologySection>
          <MethodologyTitle>Methodology Statement</MethodologyTitle>
          <MethodologyText>
            This study was conducted online in the U.S. by The Harris Poll on behalf of Skylight
            among 2,005 parents of children under the age of 18. The survey was conducted from May
            15 to May 22, 2024.
          </MethodologyText>
          <MethodologyText>
            Data are weighted where necessary by age, gender, race/ethnicity, region, education,
            marital status, household size, employment, and household income to bring them in line
            with their actual proportions in the population.
          </MethodologyText>
          <MethodologyText>
            The sampling precision of Harris online polls is measured by using a Bayesian credible
            interval. For this study, the sample data is accurate to within +2.9% percentage points
            using a 95% confidence level.
          </MethodologyText>
          <MethodologyText>Numbers shown are rounded from the calculated values.</MethodologyText>
        </MethodologySection>
      </MentalLoadPage>
    </>
  )
}
export const MentalLoadPage = styled.div`
  color: ${(props) => props.theme.emerald};
  background-color: white;
  a {
    text-decoration: none;
  }
`
export const LargeContainer = styled.div`
  width: 90%;
  margin: auto;
`

export const Heading = styled.h1`
  color: ${(props) => props.theme.lime};
  font-family: 'FilsonProBook';
  font-size: 38px;
  line-height: 32.119px;
  letter-spacing: 0.115px;
  margin: 8px 0px 32px;
  .nowrap {
    white-space: nowrap;
    display: inline;
  }
  .highlight {
    font-family: 'P22MackinacProBook';
  }
  .break {
    display: inline;
  }
  @media (max-width: ${breakpoints.m}px) {
    padding: 0px;
    line-height: 120%;
    font-size: 25px;
    letter-spacing: 0.065px;
    .break {
      display: block;
    }
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 0px 0px 16px;
  }
`
export const Subheading = styled.h2`
  color: #fff;
  font-family: 'P22MackinacProBook';
  font-size: 48px;
  line-height: 126%;
  letter-spacing: 0.12px;
  max-width: 900px;
  margin: 40px auto;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 27px;
    font-style: normal;
    font-weight: 500;
    line-height: 126%;
    letter-spacing: 0.15px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 26px;
    margin: 16px auto 24px;
  }
`
export const SignUpSubheading = styled(Subheading)`
  margin: 8px auto;
`

export const StandardText = css`
  font-family: 'FilsonProBook';
  font-size: 24px;
  font-style: normal;
  font-weight: 425;
  line-height: 150%;
  letter-spacing: 0.09px;
  b {
    font-family: 'FilsonProBold';
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
  }
`
export const Text = styled.p`
  ${StandardText}
  color: ${(props) => (props.white ? '#fff' : props.theme.emerald)};
  max-width: 1100px;
  margin: auto;
`
export const SignUpText = styled.p`
  ${StandardText}
  max-width: 1200px;
  margin: 0px auto 32px;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.09px;
    b {
      font-family: 'FilsonProBold';
    }
  }
`
export const Phonetic = styled.p`
  ${StandardText}
  margin: 4px 0px 8px;
  font-family: 'Arial', sans-serif;
  font-style: italic;
`
export const Definition = styled.p`
  ${StandardText}
  span {
    font-family: 'FilsonProRegularItalic';
  }
`
export const SchedulingTitle = styled.h3`
  ${SubHeading}
  font-family: 'FilsonProBold';
  margin: 0px;
`
export const SchedulingReliefTextContainer = styled.div`
  margin-right: 80px;
  @media (max-width: ${breakpoints.xl}px) {
    margin: 40px 0px 0px 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 32px 0px 0px 0px;
  }
`
export const Details = styled.h4`
  ${SubHeading}
  text-align: center;
  width: 1000px;
  margin: 8px auto;
  max-width: 100%;
  @media (max-width: ${breakpoints.m}px) {
    margin: 4px auto;
  }
`
export const Disclaimer = styled.p`
  font-family: 'FilsonProRegularItalic';
  font-size: 18px;
  font-weight: 425;
  line-height: 150%;
  letter-spacing: 0.1px;
  max-width: ${(props) => (props.s ? '875px' : '1000px')};
  margin: 16px auto 32px;
  span {
    text-decoration: underline;
    color: #fff;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 12px;
  }
`
export const Hero = styled.div`
  text-align: center;
  background-color: ${(props) => props.theme.emerald};
`
export const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
  a:nth-of-type(1) > button {
    margin-right: 16px;
  }
  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
    a:nth-of-type(1) > button {
      margin: 0px 0px 16px;
    }
  }
`
export const EventImage = styled.div`
  width: 340px;
  @media (max-width: ${breakpoints.xl}px) {
    width: 280px;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 200px;
    margin-bottom: 16px;
  }
`
export const PieImage = styled.div`
  width: 300px;
  @media (max-width: ${breakpoints.xl}px) {
    width: 280px;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 180px;
  }
`

export const ChatImage = styled.div`
  width: 230px;
  @media (max-width: ${breakpoints.xl}px) {
    width: 180px;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 130px;
  }
`
export const CouplesImage = styled.div`
  width: 340px;
  @media (max-width: ${breakpoints.s}px) {
    width: 223px;
  }
`
export const CoupleImage = styled.div`
  width: 140px;
  margin: 50px auto 8px;
  @media (max-width: ${breakpoints.s}px) {
    width: 110px;
    margin: 16px auto;
  }
`

export const DiaperImage = styled.div`
  min-width: 180px;
  margin-right: 40px;
  @media (max-width: ${breakpoints.l}px) {
    width: 90px;
    min-width: 90px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin-right: 0px;
  }
`

export const ParentsSection = styled.div`
  text-align: center;
  background-color: white;
  @media (max-width: ${breakpoints.l}px) {
    padding: 0px 48px 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: 0px 0px 16px;
  }
`
export const OverScheduledSection = styled(ParentsSection)`
  padding: 110px 0px;
  @media (max-width: ${breakpoints.l}px) {
    padding: 48px 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: 40px 0px;
  }
`
export const ParentsSpendContainer = styled.div`
  margin: 40px 0px;
  @media (max-width: ${breakpoints.s}px) {
    margin: 0px 0px 40px;
  }
`
export const StandardContainer = styled.div`
  width: 90%;
  margin: auto;
`
export const StatsHeading = styled.h3`
  color: ${(props) => props.theme.emerald};
  font-family: 'FilsonProBold';
  font-size: 53px;
  font-style: normal;
  font-weight: 700;
  line-height: 115%;
  letter-spacing: 0.12px;
  color: ${(props) => props.theme.emerald};
  margin: 0px;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 28px;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: 0.15px;
  }
`
export const StatsSubheading = styled.p`
  font-family: 'FilsonProBook';
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 138%;
  letter-spacing: 0.09px;
  b {
    font-family: 'FilsonPro';
  }
  @media (max-width: 1400px) {
    font-size: 24px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
    margin: 8px 0px 16px;
  }
`
export const ParentalSection = styled.div`
  color: ${(props) => props.theme.emerald};
  background-color: ${(props) => props.theme.lavender};
  padding: 16px 0px 8px;
  @media (max-width: ${breakpoints.s}px) {
    padding: 0px;
  }
`

export const SignUpSection = styled.div`
  background-color: ${(props) => props.theme.emerald};
  margin: 0px;
  text-align: center;
  color: white;
  padding: 70px 0px;
  @media (max-width: ${breakpoints.m}px) {
    text-align: left;
    padding: 32px 0px 16px;
  }
`
export const MethodologySection = styled.div`
  text-align: center;
  max-width: 1010px;
  padding: 38px 0px;
  margin: auto;
  @media (max-width: ${breakpoints.xl}px) {
    max-width: 90%;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 12px;
    font-style: normal;
    font-weight: 425;
    line-height: 150%;
    letter-spacing: 0.09px;
  }
`
export const MethodologyTitle = styled.h3`
  font-size: 32px;
  line-height: 126%;
  letter-spacing: 0.12px;
  margin: 0px;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 18px;
    letter-spacing: 0.137px;
    text-align: left;
  }
`
export const MethodologyText = styled.p`
  color: ${(props) => props.theme.emerald};
  font-size: 18px;
  font-style: normal;
  font-weight: 425;
  line-height: 150%;
  letter-spacing: 0.1px;
  @media (max-width: ${breakpoints.m}px) {
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: 425;
    line-height: 150%;
    letter-spacing: 0.09px;
  }
`
export const ChatContainer = styled.div`
  display: grid;
  grid-template-columns: 25% 5% 70%;
  gap: 0;
  width: 100%;
  align-items: center;
  justify-items: center;
  justify-content: start;
  padding-left: 15%;
  padding-right: 5%;
  @media (max-width: ${breakpoints.l}px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    padding-left: 0;
    justify-content: center;
  }
  @media (max-width: ${breakpoints.m}px) {
    padding-left: 0%;
    padding-right: 0%;
  }
`

const FirstDiv = styled.div`
  grid-column: 1 / 2;

  @media (max-width: ${breakpoints.l}px) {
    grid-column: auto;
    grid-row: 1 / 2;
  }
`

const SecondDiv = styled.div`
  grid-column: 3 / 4;

  @media (max-width: ${breakpoints.l}px) {
    grid-column: auto;
    grid-row: 2 / 3;
  }
`
export const ChatStats = styled.h4`
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 138%;
  letter-spacing: 0.108px;
  margin: 8px 0px;
  color: ${(prop) => prop.theme.emerald};
  @media (max-width: 1400px) {
    font-size: 28px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
    letter-spacing: 0.041px;
  }
  @media (max-width: 380px) {
    font-size: 18px;
  }
`

export const Stats = styled.p`
  font-family: 'FilsonProBook';
  font-size: 24px;
  font-style: normal;
  font-weight: 425;
  line-height: 115%;
  letter-spacing: 0.127px;
  margin: 0px auto;
  width: 496px;
  max-width: 100%;
  b {
    font-family: 'FilsonPro';
  }
  @media (max-width: ${breakpoints.m}px) {
    text-align: center;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: 0.037px;
    width: 100%;
  }
`
export const StatContainer = styled.div`
  text-align: ${(props) => (props.left ? 'left' : 'right')};
  margin-left: 75px;
  @media (max-width: ${breakpoints.xl}px) {
    margin-left: 40px;
  }
  @media (max-width: ${breakpoints.m}px) {
    max-width: 100%;
    text-align: center;
    margin: 24px 0px 32px;
  }
`
export const SpecialStatsContainer = styled(StatContainer)`
  max-width: 310px;
  @media (max-width: ${breakpoints.m}px) {
    max-width: 100%;
  }
`

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  margin-top: 0px;
`
export const StatsTextContainer = styled.div`
  max-width: 1000px;
  margin: auto;
`
export const TaskStatsTextContainer = styled.div`
  max-width: 800px;
  margin: auto;
`
export const TwoColumnContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 60px 0px;
  @media (max-width: ${breakpoints.m}px) {
    flex-direction: ${(props) => (props.column ? 'column' : 'column-reverse')};
    margin: 0px;
  }
`
export const QuizBanner = styled.div`
  background-color: ${(props) => props.theme.violet};
  text-align: center;
  padding: 12px 0px 10px;
  p {
    color: ${(props) => props.theme.emerald};
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.106px;
    margin: 0px;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 18px;
    padding: 10px 0px 8px;
  }
`
export const SchedulingText = styled.p`
  ${StandardText}
  @media (max-width: 1500px) {
    font-size: 18px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 8px 0px 16px;
  }
`
export const SchedulingTwoColumnContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 60px 0px;
  @media (max-width: ${breakpoints.xl}px) {
    flex-direction: ${(props) => (props.column ? 'column' : 'column-reverse')};
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: 40px 0px;
  }
`
export const Divider = styled.div`
  width: 183px;
  height: 6px;
  background-color: ${(props) => props.theme.glow};
  margin: 80px auto;
  @media (max-width: ${breakpoints.m}px) {
    margin: 40px auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 90px;
    height: 3px;
  }
`
export const FlexBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0px 60px;
  @media (max-width: ${breakpoints.m}px) {
    flex-direction: ${(props) => (props.reverse ? 'column-reverse' : 'column')};
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 16px 0px;
  }
`
export const SignUpFormContainer = styled.div`
  width: 940px;
  margin: auto;
  max-width: 100%;
`

export const MediumUnderline = styled.span`
  position: relative;
  display: inline-block;
  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 16px;
    height: 5px;
    background: ${(props) =>
      props.glow ? props.theme.glow : props.emerald ? props.theme.emerald : props.theme.lime};
    z-index: -1;
  }
  @media (max-width: ${breakpoints.m}px) {
    &::before {
      bottom: 7px;
      height: 3px;
    }
  }
`
export const SmallUnderline = styled.span`
  position: relative;
  display: inline-block;
  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    height: 5px;
    background: ${(props) =>
      props.glow ? props.theme.glow : props.emerald ? props.theme.emerald : props.theme.lime};
    z-index: -1;
  }
  @media (max-width: ${breakpoints.m}px) {
    &::before {
      bottom: 10px;
      height: 3px;
    }
  }
  @media (max-width: ${breakpoints.s}px) {
    &::before {
      bottom: 5px;
      height: 4px;
    }
  }
`
export const ParentalTitle = styled.h3`
  ${SubHeading}
  font-family: 'FilsonProBold';
  margin: 0px;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 32px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 20px;
  }
`
export const HighlightedText = styled.span`
  position: relative;
  display: inline-block;
  z-index: 1;
  line-height: 115%;
  font-size: 58.092px;
  font-family: 'P22MackinacProBold';
  font-size: ${(props) =>
    props.large ? '80px' : props.medium ? '80px' : props.xl ? '146px' : '38px'};
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: ${(props) =>
      props.large ? '16px' : props.medium ? '18px' : props.xl ? '34px' : '7px'};
    height: ${(props) =>
      props.large ? '10px' : props.medium ? '10px' : props.xl ? '15px' : '5px'};
    background-color: ${(props) =>
      props.glow ? props.theme.glow : props.emerald ? props.theme.emerald : props.theme.lime};
    z-index: -1;
  }
  @media (max-width: 1400px) {
    font-size: ${(props) =>
      props.large ? '64px' : props.medium ? '55px' : props.xl ? '88px' : '38px'};
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: ${(props) =>
        props.large ? '10px' : props.medium ? '12px' : props.xl ? '22px' : '7px'};
    }
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: ${(props) =>
      props.large ? '48px' : props.medium ? '40px' : props.xl ? '75px' : '27px'};
    line-height: 115%;
    &::before {
      bottom: ${(props) =>
        props.large ? '10px' : props.medium ? '5px' : props.xl ? '17px' : '7px'};
      height: ${(props) => (props.large ? '7px' : props.medium ? '6px' : props.xl ? '9px' : '5px')};
    }
  }
  @media (max-width: ${breakpoints.s}px) {
    letter-spacing: 0.1;
    font-size: ${(props) =>
      props.large ? '48px' : props.medium ? '28px' : props.xl ? '75px' : '18px'};
    &::before {
      bottom: ${(props) =>
        props.large ? '10px' : props.medium ? '5px' : props.xl ? '17px' : '4px'};
      height: ${(props) => (props.large ? '7px' : props.medium ? '6px' : props.xl ? '9px' : '3px')};
    }
  }
  @media (max-width: 350px) {
    letter-spacing: 0.1;
    font-size: ${(props) =>
      props.large ? '40px' : props.medium ? '28px' : props.xl ? '75px' : '15px'};
    &::before {
      bottom: ${(props) =>
        props.large ? '10px' : props.medium ? '5px' : props.xl ? '17px' : '4px'};
      height: ${(props) => (props.large ? '7px' : props.medium ? '6px' : props.xl ? '9px' : '3px')};
    }
  }
`

export default MentalLoad

MentalLoad.propTypes = {
  data: PropTypes.shape({
    family: PropTypes.object.isRequired,
    familyMobile: PropTypes.object.isRequired,
    familyCouch: PropTypes.object.isRequired,
    familyCouchMobile: PropTypes.object.isRequired,
    familySchedule: PropTypes.object.isRequired,
    familyScheduleMobile: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    pie: PropTypes.object.isRequired,
    couples: PropTypes.object.isRequired,
    couple: PropTypes.object.isRequired,
    diaper: PropTypes.object.isRequired,
    events: PropTypes.object.isRequired,
  }).isRequired,
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "calendar"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    family: file(relativePath: { eq: "mental-load/family.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    familyMobile: file(relativePath: { eq: "mental-load/family-mobile.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    familyCouch: file(relativePath: { eq: "mental-load/family-couch.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    familyCouchMobile: file(relativePath: { eq: "mental-load/family-couch-mobile.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    familySchedule: file(relativePath: { eq: "mental-load/family-schedule.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    familyScheduleMobile: file(relativePath: { eq: "mental-load/family-schedule-mobile.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    chat: file(relativePath: { eq: "mental-load/chat.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    pie: file(relativePath: { eq: "mental-load/head-pie.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    couples: file(relativePath: { eq: "mental-load/couples.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    couple: file(relativePath: { eq: "mental-load/couple.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    diaper: file(relativePath: { eq: "mental-load/diaper.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    events: file(relativePath: { eq: "mental-load/events.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
  }
`
