import styled, { css } from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { darken, lighten } from 'polished'

export const QuizSection = styled.div`
  background-color: ${(props) => props.theme.sage};
  position: relative;
  margin-top: ${(props) => (props.homepage ? '-16px' : '0px')};
  @media (max-width: ${breakpoints.l}px) {
    margin-top: ${(props) => (props.homepage ? '32px' : '0px')};
  }
`

export const QuizContainer = styled.div`
  display: flex;
  width: 90%;
  margin: auto;
  padding: ${(props) => (props.center ? '0px' : '40px 0px')};
  align-items: center;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  @media (max-width: ${breakpoints.m}px) {
    flex-direction: column-reverse;
  }
`
export const SubHeading = css`
  color: ${(props) => props.theme.emerald};
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%;
  letter-spacing: 0.114px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.037px;
  }
`
export const QuizTitle = styled.h3`
  ${SubHeading}
  font-family: ${(props) => (props.calendar ? 'FilsonPro' : 'P22MackinacProBook')};
  color: ${(prop) => prop.theme.emerald};
  margin: ${(props) => (props.fullWidth ? 'auto' : '0px')};
  span {
    font-style: italic;
  }
  width: ${(props) => (props.fullWidth ? '100%' : '450px')};
  max-width: 100%;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 32px;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 25px;
    line-height: 115%;
    text-align: center;
    width: 100%;
  }
  @media (max-width: ${breakpoints.s}px) {
    text-align: ${(props) => (props.fullWidth ? 'center' : 'left')};
    width: 350px;
  }
  @media (max-width: 380px) {
    width: 250px;
    width: ${(props) => (props.fullWidth ? '100%' : '250px')};
  }
`
export const QuizText = styled.p`
  font-family: 'FilsonProBook';
  font-size: 18px;
  font-style: normal;
  font-weight: 425;
  line-height: 150%;
  letter-spacing: 0.09px;
  width: ${(props) => (props.fullWidth ? '100%' : '450px')};
  max-width: 100%;
  @media (max-width: ${breakpoints.m}px) {
    text-align: center;
    width: 100%;
  }
  @media (max-width: ${breakpoints.s}px) {
    text-align: ${(props) => (props.fullWidth ? 'center' : 'left')};
    font-size: ${(props) => (props.fullWidth ? '16px' : '18px')};
  }
`
export const ChangeMobileDesktop = css`
  > div:nth-of-type(1),
  > p:nth-of-type(1),
  > h4:nth-of-type(1) {
    display: block;
  }
  > div:nth-of-type(2),
  > p:nth-of-type(2),
  > h4:nth-of-type(2) {
    display: none;
  }
  @media (max-width: ${breakpoints.m}px) {
    > div:nth-of-type(1),
    > p:nth-of-type(1),
    > h4:nth-of-type(1) {
      display: none;
    }
    > div:nth-of-type(2),
    > p:nth-of-type(2),
    > h4:nth-of-type(2) {
      display: block;
    }
  }
`

export const CopyChange = styled.div`
  ${ChangeMobileDesktop}
`
export const FamilyImage = styled.div`
  ${ChangeMobileDesktop}
  max-width: 800px;
  margin: auto;
  @media (max-width: ${breakpoints.s}px) {
    max-width: 80%;
  }
`
export const FamilyCouchImage = styled.div`
  ${ChangeMobileDesktop}
  display: ${(props) => (props.hideImage ? 'none' : 'block')};
  width: 75%;
  min-width: 250px;
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
`
export const FamilyScheduleImage = styled.div`
  ${ChangeMobileDesktop}
  min-width: 40%;
  @media (max-width: ${breakpoints.xl}px) {
    width: 60%;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
`
export const Button = styled.button`
  font-family: 'FilsonPro';
  display: inline-block;
  background-color: ${(props) => (props.lime ? props.theme.lime : props.theme.violet)};
  color: ${(props) => props.theme.emerald};
  text-decoration: none;
  border-radius: 38px;
  border: none;
  font-size: 18px;
  padding: 15px 30px;
  letter-spacing: 0.09px;
  line-height: 1;
  text-transform: ${(props) => (props.fullWidth ? 'none' : 'uppercase')};
  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.lime ? darken(0.1, props.theme.lime) : darken(0.1, props.theme.violet)};
  }
  &:focus {
    outline: none;
    box-shadow: ${(props) =>
      props.lime
        ? `0 0 0 0.2rem ${lighten(0.1, props.theme.lime)}`
        : `0 0 0 0.2rem ${lighten(0.1, props.theme.violet)}`};
  }
  @media (max-width: 650px) {
    font-size: 15px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 14px;
  }
  @media (max-width: 350px) {
    font-size: 13px;
    padding: 15px 20px;
  }
`
export const Container = styled.div`
  width: ${(props) => (props.fullWidth ? '90%' : '70%')};
  margin: auto;
  padding: 64px 0px;
  @media (max-width: ${breakpoints.l}px) {
    width: 90%;
  }
  @media (max-width: ${breakpoints.m}px) {
    padding: 32px 0px;
  }
`
export const CenterOnMobile = styled.div`
  @media (max-width: ${breakpoints.m}px) {
    text-align: center;
  }
`
